// @import "../../custom.scss";

.icon-circle {
  width: 60px;
  height: 60px;
  font-size: 48px;
  position: relative;
  margin: auto;
}

.icon-circle-inner {
  position: absolute;
  top: -16px;
  right: 0px;
}
