$free-color: #4ca9ed;
$plus-color: #f99aa7;
$pro-color: #29a877;
$premium-color: #eb114c;
$disabled-color: #777777;
$font-color: white;
$dark-disabled-color:#162028;
$general-color: #d3e3f4;
$gray-font-color: #606470;

:export{
    free-color: $free-color;
    plus-color: $plus-color;
    pro-color: $pro-color;
    premium-color: $premium-color;
    general-color: $general-color;
    font-color: $font-color;
    disabled-color: $disabled-color;
}