@import "./custom";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
.App {
  text-align: center;
}

//this was overriding the font for the new home page
// body {
//   font-family: "Open Sans", Helvetica, sans-serif !important;
// }

// .header {
//   position: sticky !important;
//   top: 0px;
//   z-index: 10;
//   .nav-link {
//     // color: $dark !important;
//     font-weight: 600 !important;

//     text-transform: uppercase;

//     &.active {
//       // color: #137cbd !important;
//     }
//   }
// }

.font-weight-semi-bold {
  font-weight: 600 !important;
}

// .navbar-nav {
//   .btn,
//   .nav-link,
//   .navbar-text {
//     font-size: 14px;
//   }
// }

// .btn {
//   text-transform: uppercase;
// }

.btn-padding {
  padding: 10px 20px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.align-children-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
